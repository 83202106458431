import React from 'react';
import './index.css';

export default function InfoPage() {
  return (
    <div className="DragonPath">
      <div className="main-container">
        <h1 className="title">THE DRAGON PATH</h1>
        <div className="box_content">
          <p>
            The dragon path leads to hidden statue fragments that were not
            revealed in the initial 81 art works. Collectors can discover statue
            fragments of the guardian, the assassin, and the enforcer by
            breeding special combinations of their original pieces.
          </p>
        </div>
        <h1>HOW IT WORKS</h1>
        <div className="box_content">
          <p>
            The guardian, the assassin, and the enforcer statues are considered
            tier 1 statues, and the dragon statue is the only tier 2 statue.
            Like the original statues, tier statues are broken into three
            fragments. By breeding tokens with specific TOP fragments, one can
            discover a TOP piece of one of the tier statues. Users can use the
            same logic to find MIDDLE and BOTTOM tier statue fragments as well.
            The chance of encountering the hidden fragment is roughly 20% with
            each successful breed. Follow the path and discover TOP, MIDDLE and
            BOTTOM tier 1 statue fragments!
          </p>
          <img
            className="image"
            src="https://storage.googleapis.com/cryptogifs/html/images/info/dragonpath_1.png"
            alt=""
          />
        </div>

        <h1>TOP FRAGMENTS</h1>
				<div className="code">
					<p>Clementine Hermanubis + Gold Conch + Clementine Soldier --> Marble Guardian</p>
					<p>Sky Conch + Chrome Conch + Sky Adventurer --> Clementine Guardian</p>
					<p>Shadow Thorn + Marble Astronaut + Marble Eagle --> Rose Guardian</p>
					<p>Sky Thorn + Chrome Thorn + Pearl Hermanubis --> Sky Guardian</p>
					<p>Marble Puck + Cloud Puck + Sky Hermanubis --> Shadow Guardian</p>
					<p>Shadow Astronaut + Clementine Astronaut + Shadow Conch --> Cloud Guardian</p>
					<p>Shadow Puck + Cloud Adventurer + Gold Hermanubis --> Pearl Guardian</p>
					<p>Gold Eagle + Chrome Puck + Cloud Conch --> Chrome Guardian</p>
					<p>Sky Soldier + Rose Adventurer + Marble Shepherd --> Gold Guardian</p>
					<p>Pearl Soldier + Shadow Hermanubis + Rose Eagle --> Marble Assassin</p>
					<p>Chrome Soldier + Chrome Eagle + Marble Soldier --> Clementine Assassin</p>
					<p>Shadow Eagle + Pearl Adventurer + Shadow Soldier --> Rose Assassin</p>
					<p>Clementine Conch + Cloud Shepherd + Chrome Hermanubis --> Sky Assassin</p>
					<p>Marble Thorn + Sky Eagle + Gold Soldier --> Shadow Assassin</p>
					<p>Sky Shepherd + Chrome Astronaut + Pearl Shepherd --> Cloud Assassin</p>
					<p>Clementine Eagle + Gold Thorn + Rose Shepherd --> Pearl Assassin</p>
					<p>Sky Puck + Shadow Shepherd + Gold Shepherd --> Chrome Assassin</p>
					<p>Cloud Thorn + Cloud Astronaut + Cloud Hermanubis --> Gold Assassin</p>
					<p>Clementine Thorn + Clementine Shepherd + Rose Astronaut --> Marble Enforcer</p>
					<p>Chrome Shepherd + Pearl Astronaut + Pearl Conch --> Clementine Enforcer</p>
					<p>Shadow Adventurer + Rose Soldier + Pearl Thorn --> Rose Enforcer</p>
					<p>Pearl Eagle + Rose Hermanubis + Marble Conch --> Sky Enforcer</p>
					<p>Rose Conch + Chrome Adventurer + Cloud Soldier --> Shadow Enforcer</p>
					<p>Rose Puck + Clementine Puck + Cloud Eagle --> Cloud Enforcer</p>
					<p>Sky Astronaut + Clementine Adventurer + Marble Adventurer --> Pearl Enforcer</p>
					<p>Rose Thorn + Pearl Puck + Gold Puck --> Chrome Enforcer</p>
					<p>Gold Astronaut + Gold Adventurer + Marble Hermanubis --> Gold Enforcer</p>
				</div>

				<h1>MIDDLE FRAGMENTS</h1>
				<div className="code">
					<p>Shadow Thorn + Rose Hermanubis + Clementine Puck --> Marble Guardian</p>
					<p>Gold Hermanubis + Rose Thorn + Shadow Hermanubis --> Clementine Guardian</p>
					<p>Chrome Thorn + Shadow Conch + Clementine Astronaut --> Rose Guardian</p>
					<p>Rose Shepherd + Cloud Soldier + Gold Astronaut --> Sky Guardian</p>
					<p>Gold Thorn + Pearl Puck + Marble Eagle --> Shadow Guardian</p>
					<p>Rose Conch + Marble Soldier + Rose Eagle --> Cloud Guardian</p>
					<p>Clementine Eagle + Clementine Thorn + Pearl Eagle --> Pearl Guardian</p>
					<p>Sky Conch + Clementine Shepherd + Chrome Puck --> Chrome Guardian</p>
					<p>Rose Puck + Shadow Puck + Sky Soldier --> Gold Guardian</p>
					<p>Sky Shepherd + Cloud Eagle + Gold Puck --> Marble Assassin</p>
					<p>Chrome Conch + Clementine Hermanubis + Shadow Soldier --> Clementine Assassin</p>
					<p>Gold Shepherd + Marble Astronaut + Marble Shepherd --> Rose Assassin</p>
					<p>Cloud Thorn + Gold Eagle + Marble Puck --> Sky Assassin</p>
					<p>Chrome Hermanubis + Gold Conch + Clementine Conch --> Shadow Assassin</p>
					<p>Pearl Thorn + Rose Soldier + Pearl Adventurer --> Cloud Assassin</p>
					<p>Gold Adventurer + Sky Adventurer + Gold Soldier --> Pearl Assassin</p>
					<p>Cloud Hermanubis + Pearl Conch + Sky Puck --> Chrome Assassin</p>
					<p>Pearl Shepherd + Sky Eagle + Rose Adventurer --> Gold Assassin</p>
					<p>Shadow Adventurer + Sky Thorn + Marble Thorn --> Marble Enforcer</p>
					<p>Rose Astronaut + Marble Adventurer + Sky Astronaut --> Clementine Enforcer</p>
					<p>Clementine Adventurer + Marble Conch + Cloud Adventurer --> Rose Enforcer</p>
					<p>Shadow Astronaut + Pearl Soldier + Cloud Shepherd --> Sky Enforcer</p>
					<p>Sky Hermanubis + Cloud Astronaut + Shadow Eagle --> Shadow Enforcer</p>
					<p>Shadow Shepherd + Chrome Soldier + Cloud Puck --> Cloud Enforcer</p>
					<p>Clementine Soldier + Cloud Conch + Pearl Hermanubis --> Pearl Enforcer</p>
					<p>Chrome Shepherd + Chrome Eagle + Pearl Astronaut --> Chrome Enforcer</p>
					<p>Chrome Astronaut + Marble Hermanubis + Chrome Adventurer --> Gold Enforcer</p>
				</div>

				<h1>BOTTOM FRAGMENTS</h1>
				<div className="code">
					<p>Clementine Soldier + Sky Puck + Cloud Puck --> Marble Guardian</p>
					<p>Chrome Soldier + Rose Shepherd + Cloud Thorn --> Clementine Guardian</p>
					<p>Shadow Soldier + Cloud Astronaut + Cloud Hermanubis --> Rose Guardian</p>
					<p>Pearl Hermanubis + Cloud Eagle + Shadow Hermanubis --> Sky Guardian</p>
					<p>Gold Shepherd + Marble Hermanubis + Sky Conch --> Shadow Guardian</p>
					<p>Marble Shepherd + Pearl Shepherd + Gold Conch --> Cloud Guardian</p>
					<p>Shadow Astronaut + Shadow Conch + Clementine Adventurer --> Pearl Guardian</p>
					<p>Pearl Soldier + Marble Conch + Pearl Astronaut --> Chrome Guardian</p>
					<p>Pearl Thorn + Gold Puck + Rose Hermanubis --> Gold Guardian</p>
					<p>Pearl Eagle + Sky Soldier + Clementine Shepherd --> Marble Assassin</p>
					<p>Cloud Shepherd + Clementine Hermanubis + Gold Eagle --> Clementine Assassin</p>
					<p>Gold Soldier + Chrome Conch + Sky Eagle --> Rose Assassin</p>
					<p>Marble Eagle + Gold Thorn + Gold Adventurer --> Sky Assassin</p>
					<p>Pearl Conch + Pearl Adventurer + Sky Shepherd --> Shadow Assassin</p>
					<p>Marble Thorn + Cloud Conch + Shadow Puck --> Cloud Assassin</p>
					<p>Cloud Adventurer + Gold Hermanubis + Shadow Shepherd --> Pearl Assassin</p>
					<p>Shadow Thorn + Gold Astronaut + Sky Thorn --> Chrome Assassin</p>
					<p>Rose Eagle + Clementine Thorn + Chrome Adventurer --> Gold Assassin</p>
					<p>Marble Soldier + Cloud Soldier + Rose Soldier --> Marble Enforcer</p>
					<p>Pearl Puck + Rose Conch + Chrome Thorn --> Clementine Enforcer</p>
					<p>Chrome Astronaut + Clementine Astronaut + Marble Astronaut --> Rose Enforcer</p>
					<p>Shadow Eagle + Sky Adventurer + Rose Astronaut --> Sky Enforcer</p>
					<p>Rose Thorn + Chrome Hermanubis + Chrome Shepherd --> Shadow Enforcer</p>
					<p>Rose Adventurer + Rose Puck + Clementine Conch --> Cloud Enforcer</p>
					<p>Shadow Adventurer + Marble Puck + Clementine Puck --> Pearl Enforcer</p>
					<p>Clementine Eagle + Chrome Eagle + Marble Adventurer --> Chrome Enforcer</p>
					<p>Chrome Puck + Sky Astronaut + Sky Hermanubis --> Gold Enforcer</p>
				</div>

        <h1>THE DRAGON'S DEN</h1>
				<div className="box_content">		 
				<p>
				The key to discovering the dragon rests within fragments of the assassin, the enforcer, and the guardian. The tier 1 beasts each embody characteristics of the dragon, and only their combined presence can unlock from the mystical creature.</p><p>Follow the final steps of the dragon path by combining TOPs, MIDDLEs, and BOTTOMs of tier 1 statues to unlock the respective TOP, MIDDLE and BOTTOM dragon fragments. The same logic in discovering tier 1 fragments applies to finding the tier 2 dragon fragments.
				</p>
				</div>

				<h1>TOP TIER 1 FRAGMENTS</h1>
				<div className="code">
				<p> Cloud Assassin + Clementine Enforcer + Chrome Guardian --> Marble Dragon</p>
				<p> Rose Assassin + Cloud Guardian + Marble Enforcer --> Clementine Dragon</p>
				<p> Clementine Guardian + Rose Enforcer + Pearl Assassin --> Rose Dragon</p>
				<p> Gold Assassin + Sky Guardian + Cloud Enforcer --> Sky Dragon</p>
				<p> Chrome Assassin + Pearl Guardian + Shadow Enforcer --> Shadow Dragon</p>
				<p> Clementine Assassin + Sky Enforcer + Shadow Guardian --> Cloud Dragon</p>
				<p> Rose Guardian + Gold Enforcer + Sky Assassin --> Pearl Dragon</p>
				<p> Chrome Enforcer + Gold Guardian + Marble Assassin --> Chrome Dragon</p>
				<p> Marble Guardian + Pearl Enforcer + Shadow Assassin --> Gold Dragon</p>
			</div>
				<h1>MIDDLE TIER 1 FRAGMENTS</h1>
				<div className="code">
				<p> Rose Assassin + Clementine Guardian + Sky Enforcer --> Marble Dragon</p>
				<p> Pearl Assassin + Marble Guardian + Rose Enforcer --> Clementine Dragon</p>
				<p> Clementine Assassin + Shadow Guardian + Gold Enforcer --> Rose Dragon</p>
				<p> Pearl Guardian + Marble Assassin + Clementine Enforcer --> Sky Dragon</p>
				<p> Cloud Enforcer + Sky Guardian + Gold Assassin --> Shadow Dragon</p>
				<p> Pearl Enforcer + Rose Guardian + Cloud Assassin --> Cloud Dragon</p>
				<p> Chrome Enforcer + Cloud Guardian + Shadow Assassin --> Pearl Dragon</p>
				<p> Shadow Enforcer + Chrome Assassin + Gold Guardian --> Chrome Dragon</p>
				<p> Sky Assassin + Marble Enforcer + Chrome Guardian --> Gold Dragon</p>
			</div>
				<h1>BOTTOM TIER 1 FRAGMENTS</h1>
				<div className="code">
				<p> Chrome Guardian + Pearl Assassin + Shadow Enforcer --> Marble Dragon</p>
				<p> Rose Assassin + Pearl Enforcer + Marble Guardian --> Clementine Dragon</p>
				<p> Sky Assassin + Shadow Guardian + Marble Enforcer --> Rose Dragon</p>
				<p> Pearl Guardian + Cloud Assassin + Chrome Enforcer --> Sky Dragon</p>
				<p> Rose Enforcer + Clementine Guardian + Shadow Assassin --> Shadow Dragon</p>
				<p> Marble Assassin + Cloud Enforcer + Sky Guardian --> Cloud Dragon</p>
				<p> Cloud Guardian + Gold Assassin + Clementine Enforcer --> Pearl Dragon</p>
				<p> Gold Enforcer + Chrome Assassin + Rose Guardian --> Chrome Dragon</p>
				<p> Clementine Assassin + Gold Guardian + Sky Enforcer --> Gold Dragon</p>
			</div>
      </div>
    </div>
  );
}
