import React from 'react';

import './StatueRoll.css';
import { StatueColor, StatueType } from '../../statue-helpers'; // TODO: Fix this (current GCP issue);
import { StatueData } from '../../utils/statueHelpers';

export default function StatueRoll({ dna, statue, videoUrl }: StatueData) {
  return (
    <div className="StatueRoll">
      <p className="dna">{dna}</p>
      <video autoPlay playsInline muted loop>
        <source src={videoUrl} type="video/mp4" />
        Your browser does not support HTML5 video.
      </video>
      <div className="types">
        <ul>
          <li>
            <span>TOP:</span>
            <span>
              {StatueColor[statue.topColor]} {StatueType[statue.topStatue]}
            </span>
          </li>
          <li>
            <span>MIDDLE:</span>
            <span>
              {StatueColor[statue.middleColor]}{' '}
              {StatueType[statue.middleStatue]}
            </span>
          </li>
          <li>
            <span>BOTTOM:</span>
            <span>
              {StatueColor[statue.bottomColor]}{' '}
              {StatueType[statue.bottomStatue]}
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
}
