import React, {
  PropsWithChildren,
  useEffect,
  createContext,
  useState,
} from 'react';
import { Link, useLocation } from 'react-router-dom';

import './index.css';
import Icon from '../../assets/icon.png';
import MetamaskButton from '../MetamaskButton';

export const AccountContext = createContext<{
  accounts: string[];
  setAccounts: React.Dispatch<React.SetStateAction<string[]>>;
}>({
  accounts: [],
  setAccounts: () => {},
});

const menuRoutes = [
  {
    to: '/browse',
    name: 'browse',
  },
  {
    to: '/collect',
    name: 'collect',
  },
  {
    to: 'https://storage.cloud.google.com/cryptogifs/html/farm.html?_ga=2.99051801.-646224511.1609482419',
    name: 'farm',
  },
  {
    to: '/dragonpath',
    name: 'dragonpath',
  },
  {
    to: '/info',
    name: 'info',
  },
];

export default function AppShell({ children }: PropsWithChildren<{}>) {
  const [accounts, setAccounts] = useState<string[]>([]);

  useEffect(() => {
    const ethereum = (window as any).ethereum;

    if (!ethereum) {
      return;
    }

    ethereum
      .request({ method: 'eth_accounts' })
      .then((a: string[]) => {
        console.log('OnMount accounts', a);
        setAccounts(a);
      })
      .catch((err: any) => {
        // Some unexpected error.
        // For backwards compatibility reasons, if no accounts are available,
        // eth_accounts will return an empty array.
        console.error(err);
      });

    ethereum.on('chainChanged', () => {
      console.log('Listener: Chain Changed');
      // Handle the new chain.
      window.location.reload();
    });

    ethereum.on('accountsChanged', (a: string[]) => {
      console.log('Listener: Accounts Changed', a);
      setAccounts(a);
    });
  }, [setAccounts]);

  const location = useLocation();

  return (
    <AccountContext.Provider value={{ accounts, setAccounts }}>
      <div className="AppShell">
        <div id="header">
          <div id="logo">
            <Link to="/">
              <img id="icon" src={Icon} alt="" />
              GLUEWAVE
            </Link>
          </div>

          <div id="menu">
            {menuRoutes.map(({ to, name }, index) => (
              <React.Fragment key={to}>
                {to.startsWith('https') ? (
                  <a href={to} target="_blank" rel="noreferrer">
                    {name}
                  </a>
                ) : (
                  <Link
                    to={to}
                    className={location.pathname.includes(to) ? 'match' : ''}
                  >
                    {name}
                  </Link>
                )}

                {index !== menuRoutes.length - 1 ? ' | ' : null}
              </React.Fragment>
            ))}
          </div>

          <div className="metamask-button">
            <MetamaskButton />
          </div>
        </div>
        {children}
        <div id="leftsidebar">ARWEAVE GUARANTEED ASSET</div>
        <div id="rightsidebar">0/81 DRAGONPATHS DISCOVERED</div>
      </div>
    </AccountContext.Provider>
  );
}
