import React from 'react';
import { drizzleReactHooks } from '@drizzle/react-plugin';
import { Drizzle } from '@drizzle/store';
import { ZeitProvider } from '@zeit-ui/react';

import drizzleOptions from '../../drizzleOptions';
import App from '../App';

const drizzle = new Drizzle(drizzleOptions as any);
const { DrizzleProvider } = drizzleReactHooks;

export default function Root() {
  return (
    <ZeitProvider>
      <DrizzleProvider drizzle={drizzle}>
        <App />
      </DrizzleProvider>
    </ZeitProvider>
  );
}
