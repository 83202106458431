import React from 'react';
import LazyLoad from 'react-lazyload';

import { useHistory } from 'react-router-dom';

import './index.css';
import {
  Statue,
  StatueColor,
  StatueType,
  BgColor,
  BgObject,
} from '../../statue-helpers'; // TODO: Fix this (current GCP issue);

type Props = {
  statue: Statue;
  videoUrl: string;
  statueId?: string;
  dna: string;
  footer?: React.ReactNode;
};

function Placeholder({ dna }: { dna: string }) {
  return <div className={`placeholder color-${dna.slice(0, 2)}`}></div>;
}

export default function GalleryItem({
  statue,
  videoUrl,
  statueId,
  dna,
  footer = null,
}: Props) {
  const history = useHistory();
  const inProgress = !statueId;

  return (
    <div className="GalleryItem">
      {inProgress ? (
        <div className="title">
          <h2 className="header">RENDER</h2>
          <p>{dna}</p>
        </div>
      ) : (
        <div
          className="title clickable"
          onClick={() => history.push(`/browse/${statueId}`)}
        >
          <h2 className="header">RENDER</h2>
          <p>{dna}</p>
        </div>
      )}

      <div className={`placeholder color-${dna.slice(0, 2)}`}>
        <LazyLoad
          height={400}
          offset={400}
          unmountIfInvisible
          placeholder={<Placeholder dna={dna} />}
        >
          <video muted autoPlay playsInline loop>
            <source src={videoUrl} type="video/mp4" />
            Your browser does not support HTML5 video.
          </video>
        </LazyLoad>
      </div>

      {/* TODO: Make the attributes its own component */}
      <div className="attributes statue">
        <ul>
          <li>
            TOP: {StatueColor[statue.topColor]} {StatueType[statue.topStatue]}
          </li>
          <li>
            MIDDLE: {StatueColor[statue.middleColor]}{' '}
            {StatueType[statue.middleStatue]}
          </li>
          <li>
            BOTTOM: {StatueColor[statue.bottomColor]}{' '}
            {StatueType[statue.bottomStatue]}
          </li>
        </ul>
      </div>
      <div className="attributes objects">
        <ul>
          <li>SCENE: {BgColor[statue.bgColor]}</li>
          <li>BG OBJECT 1: {BgObject[statue.bgObjectOne]}</li>
          <li>BG OBJECT 2: {BgObject[statue.bgObjectTwo]}</li>
        </ul>
      </div>
      {footer}
    </div>
  );
}
