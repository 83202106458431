import React from 'react';
import Dropdown from '../Dropdown';
import {
  BgColor,
  StatueColor,
  StatueType,
  BgObject,
  StatueKeys,
} from '../../statue-helpers'; // TODO: Fix this (current GCP issue);

import './index.css';

export const filtersMap: { [key: string]: any } = {
  scene: BgColor,
  top: StatueType,
  'top color': StatueColor,
  middle: StatueType,
  'middle color': StatueColor,
  bottom: StatueType,
  'bottom color': StatueColor,
  obj1: BgObject,
  obj2: BgObject,
};

export const filterNameToStatueKey = {
  scene: StatueKeys.bgColor,
  top: StatueKeys.topStatue,
  'top color': StatueKeys.topColor,
  middle: StatueKeys.middleStatue,
  'middle color': StatueKeys.middleColor,
  bottom: StatueKeys.bottomStatue,
  'bottom color': StatueKeys.bottomColor,
  obj1: StatueKeys.bgObjectOne,
  obj2: StatueKeys.bgObjectTwo,
};

type Props = {
  onFilterChange: (filter: Record<string, number | null>) => void;
};

export default function StatueFilter({ onFilterChange }: Props) {
  const [filters, setFilters] = React.useState<Record<string, number | null>>(
    {}
  );
  React.useEffect(() => {
    onFilterChange(filters);
  }, [filters, onFilterChange]);

  return (
    <div className="StatueFilter">
      <h4>FILTER</h4>
      <div>
        {Object.keys(filtersMap).map((type) => (
          <Dropdown
            key={type}
            anchorElement={
              <p
                className={`anchor-element ${
                  !!filters[type] || (filters[type] as unknown) === 0
                    ? 'active-item'
                    : ''
                }`}
                style={{ padding: '6px 10px 0' }}
              >
                {type}
              </p>
            }
            items={
              <ul>
                {Object.keys(filtersMap[type])
                  .filter((type) => Number.isNaN(+type))
                  .map((key) => {
                    const enumValue = filtersMap[type][key];
                    return (
                      <li
                        key={key}
                        className={`list-item ${
                          filters[type] === enumValue ? 'active-item' : ''
                        }`}
                        onClick={() => {
                          setFilters({
                            ...filters,
                            [type]:
                              filters[type] === enumValue ? null : enumValue,
                          });
                        }}
                      >
                        {key.toLowerCase()}
                      </li>
                    );
                  })}
              </ul>
            }
          />
        ))}
      </div>
    </div>
  );
}
