import React from 'react';

import './index.css';

type Props = {
  anchorElement: React.ReactElement;
  anchorPosition?: 'left' | 'right';
  items: React.ReactNode;
};

const INLINE_PADDING = 10;

export default function Dropdown({ anchorElement, items }: Props) {
  const [anchorWidth, setAnchorWidth] = React.useState(0);
  const [contentWidth, setContentWidth] = React.useState(0);
  const measuredAnchorRef = React.useCallback((node) => {
    if (node !== null) {
      const rect = node.getBoundingClientRect();
      setAnchorWidth(+rect.width);
    }
  }, []);

  const measuredContentRef = React.useCallback((node) => {
    if (node !== null) {
      const rect = node.getBoundingClientRect();
      setContentWidth(+rect.width);
    }
  }, []);

  const clone = React.cloneElement(anchorElement, { ref: measuredAnchorRef });

  return (
    <div className="Dropdown">
      <div
        className="anchor"
        style={{
          minWidth: Math.max(anchorWidth, contentWidth),
        }}
      >
        {clone}
      </div>
      <div
        className="content"
        ref={measuredContentRef}
        style={{
          minWidth: `${anchorWidth}px`,
        }}
      >
        <div
          className="content-items"
          style={{
            padding: `0 ${INLINE_PADDING}px 6px`,
          }}
        >
          {items}
        </div>
      </div>
    </div>
  );
}
