import { drizzleReactHooks } from '@drizzle/react-plugin';
import { EventData } from 'web3-eth-contract';
import { useContext } from 'react';
import { AccountContext } from '../components/AppShell';

const { useDrizzleState, useDrizzle } = drizzleReactHooks;

type DrizzleHelpers = {
  accounts: string[];
  setAccounts: React.Dispatch<React.SetStateAction<string[]>>;
  drizzleInitialized: boolean;
  drizzle: any;
  events: EventData[];
  isConnected: boolean;
};

export function useDrizzleHelper() {
  const { accounts, setAccounts } = useContext(AccountContext);
  const { drizzle } = useDrizzle();

  const drizzleHelper = useDrizzleState((state: any) => ({
    accounts: state.accounts, // drizzle uses Record<string, string> for accounts
    initialized: state.drizzleStatus.initialized,
    drizzle,
  }));

  const isConnected = drizzleHelper.initialized && !!accounts.length;

  return {
    accounts,
    setAccounts,
    drizzle: drizzleHelper.drizzle,
    drizzleInitialized: drizzleHelper.initialized,
    isConnected,
  } as DrizzleHelpers;
}
