import { intervalToDuration, fromUnixTime, isBefore, Duration } from 'date-fns';

export function toOrdinal(number: number) {
  const lastDigit = number % 10;

  const ordinal = (() => {
    if (lastDigit === 1) {
      return 'st';
    }

    if (lastDigit === 2) {
      return 'nd';
    }

    if (lastDigit === 3) {
      return 'rd';
    }

    return 'th';
  })();

  return number + ordinal;
}

export function toCooldownDuration(readyTimestamp: string) {
  const readyDate = fromUnixTime(Number(readyTimestamp));
  const now = new Date();

  if (!readyTimestamp || isBefore(readyDate, now)) {
    return null;
  }

  return intervalToDuration({
    start: readyDate,
    end: now,
  });
}

export function toCooldownText(duration: Duration) {
  if (!duration.hours) {
    return `${duration.minutes}min`;
  }

  if (!duration.minutes) {
    return `<1 min`;
  }

  return `${duration.hours}hr ${duration.minutes}min`;
}
