import React, { useEffect, useState, useCallback } from 'react';
import { Statue, decodeDna } from '../../statue-helpers'; // TODO: Fix this (current GCP issue);
import { Text, Button, useToasts } from '@zeit-ui/react';

import './index.css';
import GalleryItem from '../GalleryItem';
import { getAllOwnerTokenIds, getCanRoll } from '../../api/contractCalls';
import { useDrizzleHelper } from '../../utils/drizzleHelpers';
import {
  getAllMetadata,
  getAllReadyTimes,
  getPendingRendersDna,
  triggerRender,
} from '../../api/backend';
import RollBanner from '../RollBanner';
import renderingInProgress from '../../assets/rendering_in_progress.mp4';
import Loading from '../Loading';
import { metadataToStatue, StatueData } from '../../utils/statueHelpers';
import { toCooldownDuration, toCooldownText } from '../../utils/formatters';

interface RenderingData {
  statue: Statue;
  dna: string;
  videoUrl: string;
}

// TODO: Add an initialized and refetching states
export default function OwnerCollection() {
  const [, setToast] = useToasts();
  const { drizzle, accounts, isConnected } = useDrizzleHelper();
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [statueDatas, setStatueDatas] = useState<StatueData[]>([]);
  const [statuesToRoll, setStatuesToRoll] = useState<StatueData[]>([]);
  const [renderingStatueDatas, setRenderingStatueDatas] = useState<
    RenderingData[]
  >([]);
  const [canRoll, setCanRoll] = useState(false);
  const [fetchedReadyTimes, setFetchedReadyTimes] = useState<
    Record<string, string>
  >({});
  const [readyTimes, setReadyTimes] = useState<Record<string, Duration | null>>(
    {}
  );

  // Checks if we can roll
  useEffect(() => {
    if (!isConnected) {
      return;
    }

    getCanRoll(drizzle).then((res) => {
      setCanRoll(res);
    });
  }, [drizzle, isConnected]);

  const refetchOwnerTokens = useCallback(() => {
    getAllOwnerTokenIds(drizzle, accounts[0])
      .then((ids) => {
        getAllMetadata(ids).then((metadatas) => {
          setStatueDatas(metadatas.map(metadataToStatue));
          setIsLoading(false);
        });
      })
      .catch(() => {
        setIsLoading(false);
        setHasError(true);
      });
  }, [drizzle, accounts]);

  const refetchPendingRenders = useCallback(() => {
    if (!accounts[0]) {
      return;
    }

    getPendingRendersDna(accounts[0]).then((dnas) => {
      const pendingRenders = statueDatas.length
        ? dnas.map((dna) => ({
            dna,
            videoUrl: renderingInProgress,
            statue: decodeDna(dna),
          }))
        : [];

      setRenderingStatueDatas(pendingRenders);
    });
  }, [accounts, statueDatas]);

  // Refetches ownerTokens
  useEffect(() => {
    if (!isConnected) {
      setIsLoading(false);
      return;
    }

    // Quick hack to show loading on init but not on refetch
    setIsLoading(true);
    refetchOwnerTokens();
  }, [isConnected, refetchOwnerTokens]);

  // Refetches pendingRenders
  useEffect(() => {
    refetchPendingRenders();
  }, [refetchPendingRenders]);

  const fetchReadyTimes = useCallback(() => {
    if (!isConnected) {
      return;
    }

    getAllOwnerTokenIds(drizzle, accounts[0])
      .then((ids) => {
        getAllReadyTimes(ids).then((readyTimes) => {
          setFetchedReadyTimes(readyTimes);
          setReadyTimes(
            Object.entries(readyTimes).reduce((previous, current) => {
              return {
                ...previous,
                [current[0]]: toCooldownDuration(current[1]),
              };
            }, {})
          );
        });
      })
      .catch(() => {
        setHasError(true);
      });
  }, [accounts, drizzle, isConnected]);

  // Refetches statue readyTimes
  useEffect(() => {
    fetchReadyTimes();
  }, [fetchReadyTimes]);

  useEffect(() => {
    // don't set interval if fetchedReadyTimes is empty
    if (Object.keys(fetchedReadyTimes).length <= 0) {
      return;
    }

    // TODO: optimize to clear interval if there's nothing in fetchedReadyTimes
    const unsubscribe = setInterval(() => {
      setReadyTimes(
        Object.entries(fetchedReadyTimes).reduce(
          (previous, [statueId, timestamp]) => {
            return {
              ...previous,
              [statueId]: toCooldownDuration(timestamp),
            };
          },
          {}
        )
      );
    }, 1000);

    // TODO: optimize to clear interval if a new one is completed
    console.log('New interval set', unsubscribe);

    return () => clearInterval(unsubscribe);
  }, [fetchedReadyTimes]);

  const handleToggleClick = (statueData: StatueData) => {
    // remove the statue if it exists within statuesToRoll
    if (statuesToRoll.some((s) => s.id === statueData.id)) {
      setStatuesToRoll(statuesToRoll.filter((s) => s.id !== statueData.id));
      return;
    }

    // do nothing if statuesToRoll is "full"
    if (statuesToRoll.length >= 3) {
      return;
    }

    setStatuesToRoll([...statuesToRoll, statueData]);
  };

  return (
    <div className="OwnerCollection">
      {!isConnected ? (
        <Text h2>Please connect to MetaMask!</Text>
      ) : isLoading ? (
        <Loading />
      ) : hasError ? (
        <Text h2>
          Please check if your MetaMask is connected to the correct account
        </Text>
      ) : !statueDatas.length ? (
        <Text h2>You don't have any statues currently. Go buy some!</Text>
      ) : (
        <>
          <RollBanner
            canRoll={canRoll}
            statuesToRoll={statuesToRoll}
            onRollComplete={(requestId) => {
              setToast({ text: "We're rendering your new statue now!" });
              setStatuesToRoll([]);

              triggerRender(requestId).then(() => {
                refetchPendingRenders();
                fetchReadyTimes();
              });
            }}
          />
          <div className="list-view">
            {statueDatas.map((statueData) => {
              const { id, statue, videoUrl, dna } = statueData;
              const isOnCooldown = Boolean(readyTimes[id]);
              const buttonText = (() => {
                if (isOnCooldown) {
                  return `Cooldown Remaining: ${toCooldownText(
                    readyTimes[id]!
                  )}`;
                }

                if (statuesToRoll.some((s) => s.id === id)) {
                  return 'Remove From Slot';
                }

                return 'Add To Slot';
              })();

              return (
                <GalleryItem
                  key={id}
                  statueId={id}
                  dna={dna}
                  statue={statue}
                  videoUrl={videoUrl}
                  footer={
                    canRoll && (
                      <Button
                        type="abort"
                        style={{ width: '100%' }}
                        disabled={isOnCooldown}
                        onClick={() => handleToggleClick(statueData)}
                      >
                        {buttonText}
                      </Button>
                    )
                  }
                />
              );
            })}

            {renderingStatueDatas.map((renderingStatue, index) => {
              const { statue, videoUrl, dna } = renderingStatue;

              return (
                <GalleryItem
                  key={index}
                  statue={statue}
                  videoUrl={videoUrl}
                  dna={dna}
                />
              );
            })}
          </div>
        </>
      )}
    </div>
  );
}
