import React from 'react';

import './index.css';

export default function GlitchText({ children }: React.PropsWithChildren<{}>) {
  return (
    <div className="glitch" data-glitch={children}>
      {children}
    </div>
  );
}
