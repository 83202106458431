import React from 'react';

import GlitchText from '../GlitchText';

type Props = {
  default?: boolean;
};

export default function NotFoundPage(props: Props) {
  return <GlitchText>Not Found</GlitchText>;
}
