import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import './index.css';
import Overlay from '../../assets/overlay.svg';
import LogoBg from '../../assets/logo_bg1.mp4';
import StatueOne from '../../assets/000805060408020506.mp4';
import StatueTwo from '../../assets/010503040404080808.mp4';
import StatueThree from '../../assets/010601020708020506.mp4';
import useWindowDimensions from '../../hooks/useWindowDimensions';

export default function HomePage() {
  const { width } = useWindowDimensions();
  const history = useHistory();

  useEffect(() => {
    if (width < 768) {
      history.replace('/browse');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return (
    <div>
      <div id="maincontent">
        <div id="centercontent">
          <video autoPlay playsInline muted loop id="bgvideo">
            <source src={LogoBg} type="video/mp4" />
            Your browser does not support HTML5 video.
          </video>
          <video autoPlay playsInline muted loop id="overlayvideo1">
            <source src={StatueOne} type="video/mp4" />
            Your browser does not support HTML5 video.
          </video>
          <video autoPlay playsInline muted loop id="overlayvideo2">
            <source src={StatueTwo} type="video/mp4" />
            Your browser does not support HTML5 video.
          </video>
          <video autoPlay playsInline muted loop id="overlayvideo3">
            <source src={StatueThree} type="video/mp4" />
            Your browser does not support HTML5 video.
          </video>
          <img src={Overlay} id="dataoverlay" alt="" />
        </div>
      </div>

      <div id="footer">
        <div className="scroll-left">
          <p>
            DRAGONCODE | UNKNOWN | DRAGONPATH | UNKNOWN | DRAGONCODE | UNKNOWN |
            DRAGONPATH | UNKNOWN | DRAGONCODE | UNKNOWN | DRAGONPATH | UNKNOWN |
            DRAGONCODE | UNKNOWN | DRAGONPATH | UNKNOWN | DRAGONCODE | UNKNOWN |
          </p>
        </div>
      </div>
    </div>
  );
}
