import Web3 from 'web3';
import RollStatue from './contracts/RollStatue.json';

let web3;

// Is there an injected web3 instance?
if (typeof window.ethereum !== 'undefined') {
  console.log('dApp using MetaMask Provider');
  web3 = new Web3(window.ethereum);
} else {
  // TODO: return an error here so we can give an appropriate message to the user
  // If no injected web3 instance is detected, fallback to Ganache CLI.
  console.log('dApp using ganache-cli');
  web3 = new Web3('ws://localhost:8545');
}

const options = {
  web3: {
    block: false,
    customProvider: web3,
  },
  // TODO: Check is providing the 'data' options has any benefits
  contracts: [
    {
      contractName: 'RollStatue',
      web3Contract: new web3.eth.Contract(
        RollStatue.abi,
        process.env.REACT_APP_ROLL_ADDRESS
      ),
    },
  ],
  events: {
    RollStatue: ['RenderCompleteEvent'],
  },
};

export default options;
