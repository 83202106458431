import React, { useState, useEffect } from 'react';

import './index.css';
import GalleryItem from '../GalleryItem';
import StatueFilter, { filterNameToStatueKey } from '../StatueFilter';
import { getAllMetadata } from '../../api/backend';
import Loading from '../Loading';
import { metadataToStatue, StatueData } from '../../utils/statueHelpers';
import { Text } from '@zeit-ui/react';

const hasNoActiveFilters = (filters: Record<string, number | null>) => {
  return (
    Object.keys(filters).length === 0 ||
    Object.values(filters).every((v) => v === null)
  );
};

export default function GalleryPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [statuesData, setStatuesData] = useState<StatueData[]>([]);
  const [filters, setFilters] = useState<Record<string, number | null>>({});
  const [filteredStatueData, setFilteredStatueData] = useState(statuesData);

  useEffect(() => {
    getAllMetadata()
      .then((metadata) => {
        setStatuesData(metadata.map(metadataToStatue).reverse());
      })
      .catch(() => {
        setError('Error. Please try again another time');
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    // Iterate over all statues within GalleryPage and check each filter
    // key against each statue. Return early if there are no filters is empty
    // or if every filter is null (ie became unselected)
    const filteredData = hasNoActiveFilters(filters)
      ? statuesData
      : statuesData.filter(({ statue }) => {
          return Object.keys(filters).every((filterName) => {
            if (
              // don't filter for cases of { top: null }
              filters[filterName] !== null &&
              // @ts-ignore
              statue[filterNameToStatueKey[filterName]] !== filters[filterName]
            ) {
              return false;
            }

            return true;
          });
        });

    setFilteredStatueData(filteredData);
  }, [filters, statuesData]);

  return (
    <div className="GalleryPage">
      <StatueFilter onFilterChange={(filters) => setFilters(filters)} />

      {isLoading ? (
        <Loading />
      ) : error ? (
        <Text h2>{error}</Text>
      ) : (
        <div className="list-view">
          {filteredStatueData.map(({ id, statue, videoUrl, dna }) => (
            <GalleryItem
              key={id}
              statueId={id}
              statue={statue}
              videoUrl={videoUrl}
              dna={dna}
            />
          ))}
        </div>
      )}
    </div>
  );
}
