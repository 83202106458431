import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import AppShell from '../AppShell';
import HomePage from '../HomePage';
import StatueDetail from '../StatueDetail';
import NotFoundPage from '../NotFoundPage';
import GalleryPage from '../GalleryPage';
import InfoPage from '../InfoPage';
import DragonPath from '../DragonPath';
import OwnerCollection from '../OwnerCollection';
import AdminTools from '../AdminTools';

export default function App() {
  return (
    <Router>
      <AppShell>
        <Switch>
          <Route path="/browse/:statueId">
            <StatueDetail />
          </Route>
          <Route path="/browse">
            <GalleryPage />
          </Route>
          <Route path="/collect">
            <OwnerCollection />
          </Route>
          {/* TODO: TEST ONLY -- Remove This */}
          <Route path="/debug">
            <AdminTools />
          </Route>
          <Route path="/info">
            <InfoPage />
          </Route>
          <Route path="/dragonpath">
            <DragonPath />
          </Route>
          <Route path="/" exact>
            <HomePage />
          </Route>
          <Route path="*">
            <NotFoundPage />
          </Route>
        </Switch>
      </AppShell>
    </Router>
  );
}
