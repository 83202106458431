"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dnaOrder = exports.StatueKeys = exports.StatueColor = exports.StatueType = exports.BgObject = exports.BgColor = void 0;
var BgColor;
(function (BgColor) {
    BgColor[BgColor["Sunset"] = 0] = "Sunset";
    BgColor[BgColor["Sunrise"] = 1] = "Sunrise";
    BgColor[BgColor["Azure"] = 2] = "Azure";
    BgColor[BgColor["Moonlight"] = 3] = "Moonlight";
    BgColor[BgColor["Eclipse"] = 4] = "Eclipse";
})(BgColor = exports.BgColor || (exports.BgColor = {}));
var BgObject;
(function (BgObject) {
    BgObject[BgObject["Cheese"] = 0] = "Cheese";
    BgObject[BgObject["Turkey"] = 1] = "Turkey";
    BgObject[BgObject["Cupcake"] = 2] = "Cupcake";
    BgObject[BgObject["Chocolate"] = 3] = "Chocolate";
    BgObject[BgObject["Bananas"] = 4] = "Bananas";
    BgObject[BgObject["Baby"] = 5] = "Baby";
    BgObject[BgObject["Water Bucket"] = 6] = "Water Bucket";
    BgObject[BgObject["Bone"] = 7] = "Bone";
    BgObject[BgObject["Mushroom"] = 8] = "Mushroom";
    BgObject[BgObject["Crab"] = 9] = "Crab";
    BgObject[BgObject["Shoe"] = 10] = "Shoe";
    BgObject[BgObject["Icecream"] = 11] = "Icecream";
    BgObject[BgObject["TV"] = 12] = "TV";
    BgObject[BgObject["Chair"] = 13] = "Chair";
    BgObject[BgObject["Dagger"] = 14] = "Dagger";
    BgObject[BgObject["Starfish"] = 15] = "Starfish";
    BgObject[BgObject["Horseshoe"] = 16] = "Horseshoe";
})(BgObject = exports.BgObject || (exports.BgObject = {}));
var StatueType;
(function (StatueType) {
    StatueType[StatueType["Thorn"] = 0] = "Thorn";
    StatueType[StatueType["Conch"] = 1] = "Conch";
    StatueType[StatueType["Soldier"] = 2] = "Soldier";
    StatueType[StatueType["Eagle"] = 3] = "Eagle";
    StatueType[StatueType["Astronaut"] = 4] = "Astronaut";
    StatueType[StatueType["Shepherd"] = 5] = "Shepherd";
    StatueType[StatueType["Hermanubis"] = 6] = "Hermanubis";
    StatueType[StatueType["Adventurer"] = 7] = "Adventurer";
    StatueType[StatueType["Puck"] = 8] = "Puck";
    StatueType[StatueType["Guardian"] = 9] = "Guardian";
    StatueType[StatueType["Assassin"] = 10] = "Assassin";
    StatueType[StatueType["Enforcer"] = 11] = "Enforcer";
    StatueType[StatueType["Dragon"] = 12] = "Dragon";
})(StatueType = exports.StatueType || (exports.StatueType = {}));
var StatueColor;
(function (StatueColor) {
    StatueColor[StatueColor["Marble"] = 0] = "Marble";
    StatueColor[StatueColor["Clementine"] = 1] = "Clementine";
    StatueColor[StatueColor["Rose"] = 2] = "Rose";
    StatueColor[StatueColor["Sky"] = 3] = "Sky";
    StatueColor[StatueColor["Shadow"] = 4] = "Shadow";
    StatueColor[StatueColor["Cloud"] = 5] = "Cloud";
    StatueColor[StatueColor["Pearl"] = 6] = "Pearl";
    StatueColor[StatueColor["Chrome"] = 7] = "Chrome";
    StatueColor[StatueColor["Gold"] = 8] = "Gold";
})(StatueColor = exports.StatueColor || (exports.StatueColor = {}));
exports.StatueKeys = {
    bgColor: 'bgColor',
    bgObjectOne: 'bgObjectOne',
    bgObjectTwo: 'bgObjectTwo',
    topStatue: 'topStatue',
    middleStatue: 'middleStatue',
    bottomStatue: 'bottomStatue',
    topColor: 'topColor',
    middleColor: 'middleColor',
    bottomColor: 'bottomColor',
};
exports.dnaOrder = [
    exports.StatueKeys.bgColor,
    exports.StatueKeys.bgObjectOne,
    exports.StatueKeys.bgObjectTwo,
    exports.StatueKeys.topStatue,
    exports.StatueKeys.middleStatue,
    exports.StatueKeys.bottomStatue,
    exports.StatueKeys.topColor,
    exports.StatueKeys.middleColor,
    exports.StatueKeys.bottomColor,
];
