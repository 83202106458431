import React from 'react';

import { Button, useToasts } from '@zeit-ui/react';
import ZapOff from '@zeit-ui/react-icons/zapOff';
import Zap from '@zeit-ui/react-icons/zap';
import { useDrizzleHelper } from '../../utils/drizzleHelpers';

const ALREADY_PENDING_CODE = -32002;

export default function MetamaskButton() {
  // web3 accounts are used if a user does not already have a connected metamask
  // account. Otherwise, accounts will be populated via drizzle
  // const [web3Account, setWeb3Account] = useState('');
  const {
    // accounts,
    drizzleInitialized,
    isConnected,
    setAccounts,
  } = useDrizzleHelper();
  const [, setToast] = useToasts();

  // console.group('Metamask Button');
  // console.log('drizzle state drizzleInitialized', drizzleInitialized);
  // console.log('accounts from drizzle', accounts);
  // console.log('isConnected', isConnected);
  // console.groupEnd();

  const handleClick = () => {
    if (isConnected) {
      return;
    }

    (window as any).ethereum
      .request({ method: 'eth_requestAccounts' })
      .then((web3Accounts: string[]) => {
        setAccounts(web3Accounts);
      })
      .catch((e: any) => {
        console.log(e);
        if (e.code === ALREADY_PENDING_CODE) {
          setToast({ text: 'Request Pending. Check MetaMask' });
        }
      });
  };

  return (
    <Button
      icon={isConnected ? <Zap /> : <ZapOff />}
      type="abort"
      auto
      style={{ color: 'white' }}
      onClick={handleClick}
    >
      MetaMask{' '}
      {drizzleInitialized
        ? isConnected
          ? 'Connected'
          : 'Disconnected'
        : 'Undetected'}
    </Button>
  );
}
