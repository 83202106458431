import React, { useState, ChangeEvent } from 'react';
import { Text, Button, Input, Spacer, Page } from '@zeit-ui/react';

import { transferToken, getAllOwnerTokenIds } from '../api/contractCalls';
import { useDrizzleHelper } from '../utils/drizzleHelpers';

type Props = {};

export default function AdminTools(props: Props) {
  const [from, setFrom] = useState(
    '0x0E7cD263224A43b35DDc248416792C20432fD74d'
  );
  const [to, setTo] = useState('0xeaC611952f29b915C6094ddFA83A5d7EBB7e9C3f');
  const [tokenId, setTokenId] = useState('');

  const { accounts, drizzle } = useDrizzleHelper();

  const handleChange =
    (setter: typeof setFrom) => (e: ChangeEvent<HTMLInputElement>) => {
      setter(e.currentTarget.value);
    };
  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    transferToken(drizzle, tokenId, from, to);
  };

  return (
    <Page>
      <Text h2>Admin Tools</Text>

      <Button
        color="primary"
        onClick={() => {
          getAllOwnerTokenIds(drizzle, accounts[0]);
        }}
      >
        Get Ids
      </Button>

      <Spacer y={0.5} />

      {/* <Button
        color="primary"
        onClick={() => {
          roll(drizzle, accounts[0], '1', '2', '3');
        }}
      >
        Roll
      </Button> */}

      <Text h2>Transfer Token</Text>
      <form noValidate autoComplete="off">
        <Input
          id="token-id"
          label="Token ID"
          onChange={handleChange(setTokenId)}
        />
        <Input
          id="from"
          label="From"
          value={from}
          onChange={handleChange(setFrom)}
        />
        <Input id="to" label="To" value={to} onChange={handleChange(setTo)} />
        <Spacer y={0.5} />
        <Button onClick={handleSubmit}>Submit</Button>
      </form>
    </Page>
  );
}
