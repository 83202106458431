"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OldStatueColor = exports.OldStatueType = exports.OldBgObject = exports.OldBgColor = void 0;
var OldBgColor;
(function (OldBgColor) {
    OldBgColor[OldBgColor["Orange"] = 0] = "Orange";
    OldBgColor[OldBgColor["Pink"] = 1] = "Pink";
    OldBgColor[OldBgColor["Blue"] = 2] = "Blue";
    OldBgColor[OldBgColor["White"] = 3] = "White";
    OldBgColor[OldBgColor["Black"] = 4] = "Black";
})(OldBgColor = exports.OldBgColor || (exports.OldBgColor = {}));
var OldBgObject;
(function (OldBgObject) {
    OldBgObject[OldBgObject["Cheese"] = 0] = "Cheese";
    OldBgObject[OldBgObject["Turkey"] = 1] = "Turkey";
    OldBgObject[OldBgObject["Cupcake"] = 2] = "Cupcake";
    OldBgObject[OldBgObject["Chocolates"] = 3] = "Chocolates";
    OldBgObject[OldBgObject["Bananas"] = 4] = "Bananas";
    OldBgObject[OldBgObject["Baby"] = 5] = "Baby";
    OldBgObject[OldBgObject["WaterBucket"] = 6] = "WaterBucket";
    OldBgObject[OldBgObject["Bone"] = 7] = "Bone";
    OldBgObject[OldBgObject["Mushroom"] = 8] = "Mushroom";
})(OldBgObject = exports.OldBgObject || (exports.OldBgObject = {}));
var OldStatueType;
(function (OldStatueType) {
    OldStatueType[OldStatueType["Teen"] = 0] = "Teen";
    OldStatueType[OldStatueType["Shell"] = 1] = "Shell";
    OldStatueType[OldStatueType["Roman"] = 2] = "Roman";
    OldStatueType[OldStatueType["Eagle"] = 3] = "Eagle";
    OldStatueType[OldStatueType["Greek"] = 4] = "Greek";
    OldStatueType[OldStatueType["Girl"] = 5] = "Girl";
    OldStatueType[OldStatueType["Dog"] = 6] = "Dog";
    OldStatueType[OldStatueType["Boy"] = 7] = "Boy";
    OldStatueType[OldStatueType["Baby"] = 8] = "Baby";
    OldStatueType[OldStatueType["Rhino"] = 9] = "Rhino";
    OldStatueType[OldStatueType["Panther"] = 10] = "Panther";
    OldStatueType[OldStatueType["Bear"] = 11] = "Bear";
    OldStatueType[OldStatueType["Dragon"] = 12] = "Dragon";
})(OldStatueType = exports.OldStatueType || (exports.OldStatueType = {}));
var OldStatueColor;
(function (OldStatueColor) {
    OldStatueColor[OldStatueColor["White"] = 0] = "White";
    OldStatueColor[OldStatueColor["Orange"] = 1] = "Orange";
    OldStatueColor[OldStatueColor["Pink"] = 2] = "Pink";
    OldStatueColor[OldStatueColor["Blue"] = 3] = "Blue";
    OldStatueColor[OldStatueColor["Black"] = 4] = "Black";
    OldStatueColor[OldStatueColor["Tan"] = 5] = "Tan";
    OldStatueColor[OldStatueColor["Pearl"] = 6] = "Pearl";
    OldStatueColor[OldStatueColor["Silver"] = 7] = "Silver";
    OldStatueColor[OldStatueColor["Gold"] = 8] = "Gold";
})(OldStatueColor = exports.OldStatueColor || (exports.OldStatueColor = {}));
