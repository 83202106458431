import React, { useState, useEffect } from 'react';

import { useParams, useHistory } from 'react-router-dom';
import {
  encodeDna,
  StatueColor,
  StatueType,
  BgColor,
  BgObject,
} from '../../statue-helpers'; // TODO: Fix this (current GCP issue);
import { Loading } from '@zeit-ui/react';

import './index.css';
import { useDrizzleHelper } from '../../utils/drizzleHelpers';
import { getAllMetadata } from '../../api/backend';
import { metadataToStatue, StatueData } from '../../utils/statueHelpers';
import { toOrdinal } from '../../utils/formatters';

// TODO: add to env variable
const ARWEAVE_BASE_URL = 'https://arweave.net/';
const OPENSEA_BASE_URL = `${process.env.REACT_APP_OPENSEA_BASE_URL}assets/${process.env.REACT_APP_ROLL_ADDRESS}/`;

export default function StatueDetail() {
  const { drizzle, drizzleInitialized } = useDrizzleHelper();
  const [isLoading, setIsLoading] = useState(true);
  const [statueData, setStatueData] = useState<StatueData>({} as StatueData);
  const { statueId }: { [statueId: string]: string } = useParams();
  const history = useHistory();

  useEffect(() => {
    if (!drizzleInitialized) {
      return;
    }

    getAllMetadata([statueId])
      .then((metadatas) => metadatas[0])
      .then((metadata) => {
        setStatueData(metadataToStatue(metadata));
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(`Problem fetching data: ${err}`);
        history.replace('/not-found');
      });
  }, [drizzle, statueId, history, drizzleInitialized]);

  const { statue, videoUrl, generation, arweave } = statueData;

  return (
    <div className="StatueDetail">
      {isLoading ? (
        <Loading>Loading</Loading>
      ) : (
        <div className="content">
          <div className="title">
            <h2 className="header">RENDER</h2>
            <p>{encodeDna(statue)}</p>
          </div>

          <video autoPlay playsInline muted loop>
            <source src={videoUrl} type="video/mp4" />
            Your browser does not support HTML5 video.
          </video>

          <h3>ATTRIBUTES</h3>
          <div className="attributes">
            <div className="statue">
              <ul>
                <li>
                  TOP: {StatueColor[statue.topColor]}{' '}
                  {StatueType[statue.topStatue]}
                </li>
                <li>
                  MIDDLE: {StatueColor[statue.middleColor]}{' '}
                  {StatueType[statue.middleStatue]}
                </li>
                <li>
                  BOTTOM: {StatueColor[statue.bottomColor]}{' '}
                  {StatueType[statue.bottomStatue]}
                </li>
              </ul>
            </div>
            <div className="objects">
              <ul>
                <li>SCENE: {BgColor[statue.bgColor]}</li>
                <li>BG OBJECT 1: {BgObject[statue.bgObjectOne]}</li>
                <li>BG OBJECT 2: {BgObject[statue.bgObjectTwo]}</li>
              </ul>
            </div>
          </div>

          {generation && (
            <h3>
              <span className="ordinal">{toOrdinal(generation)}</span>{' '}
              GENERATION
            </h3>
          )}
          <h3>
            ASSET GUARANTEED ON{' '}
            <a
              href={ARWEAVE_BASE_URL + arweave}
              target="_blank"
              rel="noreferrer"
            >
              ARWEAVE
            </a>
          </h3>
          <h3>
            PURCHASE{' '}
            <a
              href={OPENSEA_BASE_URL + statueId}
              target="_blank"
              rel="noreferrer"
            >
              NOW
            </a>
            !
          </h3>
        </div>
      )}
    </div>
  );
}
