import { decodeDna, MetaDataDocument, Statue } from '../statue-helpers'; // TODO: Fix this (current GCP issue);

export interface StatueData {
  id: string;
  dna: string;
  statue: Statue;
  videoUrl: string;
  arweave: string;
  generation?: number;
}

export function metadataToStatue(metadata: MetaDataDocument): StatueData {
  const generationTrait = metadata.attributes?.find(
    (a) => a.trait_type === 'Generation'
  );

  // if (!generationTrait) {
  //   throw new Error('Invalid metadata received');
  // }

  return {
    id: metadata._id,
    dna: metadata.name,
    statue: decodeDna(metadata.name),
    videoUrl: metadata.animation_url,
    arweave: metadata.arweave,
    ...(generationTrait ? { generation: Number(generationTrait.value) } : {}),
  };
}
