import React, { useState } from 'react';
import { Button } from '@zeit-ui/react';
import {
  CornerLeftDown,
  CornerRightDown,
  AlertOctagon,
} from '@zeit-ui/react-icons';

import './index.css';
import StatueRoll from './StatueRoll';
import { roll } from '../../api/contractCalls';
import { useDrizzleHelper } from '../../utils/drizzleHelpers';
import { StatueData } from '../../utils/statueHelpers';

type Props = {
  statuesToRoll: StatueData[];
  onRollComplete: (requestId: string) => void;
  canRoll: boolean;
};

const getButtonText = (
  length: number,
  isRolling: boolean,
  isDisabled: boolean
) => {
  if (isDisabled) {
    return 'Temporarily Disabled';
  }

  if (isRolling) {
    return 'Rolling...';
  }

  if (length === 3) {
    return 'ROLL!';
  }

  return `Pick ${3 - length} More...`;
};

function StatuePlaceholder({ canRoll }: { canRoll: boolean }) {
  return (
    <div className="statue-placeholder">
      {canRoll ? (
        <>
          <CornerLeftDown />
          Select Below
          <CornerRightDown />
        </>
      ) : (
        <AlertOctagon />
      )}
    </div>
  );
}

export default function RollBanner({
  statuesToRoll,
  onRollComplete,
  canRoll,
}: Props) {
  const { drizzle, accounts } = useDrizzleHelper();
  const [isRolling, setIsRolling] = useState(false);

  const handleRoll = () => {
    setIsRolling(true);
    roll(drizzle, accounts[0], [
      statuesToRoll[0],
      statuesToRoll[1],
      statuesToRoll[2],
    ])
      .then((res) => {
        const requestId =
          res.events['ReceivedNewRequestIdEvent'].returnValues['id'];
        onRollComplete(requestId);
        setIsRolling(false);
      })
      .catch((e) => {
        console.log(e);
        setIsRolling(false);
      });
  };

  return (
    <div className="RollBanner">
      <div className="statues">
        {[0, 1, 2].map((index) => {
          const current = statuesToRoll[index];

          if (!current) {
            return (
              <StatuePlaceholder
                key={`placeholder-${index}`}
                canRoll={canRoll}
              />
            );
          }

          return (
            <div className="statue" key={`statue-${index}`}>
              <StatueRoll {...current} />
            </div>
          );
        })}
      </div>
      <Button
        size="large"
        type="abort"
        disabled={statuesToRoll.length < 3 || isRolling}
        onClick={handleRoll}
        style={{ flexGrow: 0, backgroundColor: '#556061', color: 'white' }}
      >
        {getButtonText(statuesToRoll.length, isRolling, !canRoll)}
      </Button>
    </div>
  );
}
